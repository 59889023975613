<template>
  <section class="section incorporation-faq">
    <div class="container">
      <h2 class="is-size-3">FAQ</h2>
      <b-collapse
          class="card"
          animation="slide"
          v-for="(question, index) of $t('faq')"
          :key="index"
          :open="isOpen === index"
          @open="isOpen = index"
      >
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header"
            role="button"
        >
          <p class="card-header-title">
            {{ question.question }}
          </p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'angle-up' : 'angle-down'"></b-icon>
          </a>
        </div>
        <div class="card-content">
          <div class="content">
            {{ question.answer }}
          </div>
        </div>
      </b-collapse>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'IncorporationFaq',
    data () {
      return {
        isOpen: -1
      }
    }
  }
</script>
