<template>
  <div>
    <incorporations></incorporations>
    <incorporation-faq></incorporation-faq>
  </div>
</template>

<script>
import Incorporations from '@/components/Incorporations';
import IncorporationFaq from '@/components/IncorporationFaq';

export default {
  name: 'Incorporation',
  components: { Incorporations, IncorporationFaq },
  metaInfo () {
    return {
      title: this.$t('incorporation.title'),
      meta: [
        { name: 'description', content: this.$t('incorporation.description') }
      ]
    };
  }
};
</script>
