<template>
  <section class="section">
    <div class="container">
      <div class="content">
        <h1>{{ $t('incorporation.packages') }}</h1>
        <p>{{ $t('incorporation.welcome') }}</p>
      </div>
      <div class="columns mt-5">
        <div
            v-for="pack in packages"
            :key="pack.product"
            class="column is-3"
        >
          <incorporation-box
              :product="pack.product"
              :region="pack.region"
              :title="$t(`form.package.${pack.product}.label`)"
              :subtitle="$t(`form.package.${pack.product}.description`)"
              :price="pack.price"
          >
          </incorporation-box>
        </div>
      </div>
      <div class="content mt-5">
        <h3>{{ $t('incorporation.included.title') }}:</h3>
        <ul class="fa-ul">
          <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
            {{ $t('incorporation.included.fees') }}
          </li>
          <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
            {{ $t('incorporation.included.articles') }}
          </li>
          <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
            {{ $t('incorporation.included.initialDeclaration') }}
          </li>
          <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
            {{ $t('incorporation.included.electronicBook') }}
          </li>
          <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
            {{ $t('incorporation.included.upTo') }}
          </li>
        </ul>
      </div>
      <div class="content mt-5">
        <h3>{{ $t('incorporation.options.title') }}:</h3>
        <ul class="fa-ul">
          <li><span class="fa-li"><b-icon pack="far" icon="plus-square"></b-icon></span>
            {{ $t('incorporation.options.physicalBook') }}
          </li>
          <li><span class="fa-li"><b-icon pack="far" icon="plus-square"></b-icon></span>
            {{ $t('incorporation.options.taxes') }}
          </li>
          <li><span class="fa-li"><b-icon pack="far" icon="plus-square"></b-icon></span>
            {{ $t('incorporation.options.das') }}
          </li>
          <li><span class="fa-li"><b-icon pack="far" icon="plus-square"></b-icon></span>
            {{ $t('incorporation.options.order') }}
            <span @click="displayOrderInfo">
            <b-icon
                class="incorporations__info"
                icon="info-circle"
                size="is-small"
                type="is-blue">
            </b-icon>
            </span>
          </li>
        </ul>
      </div>
      <div class="content is-small mt-6">
        <p>{{ $t('incorporation.payment') }}</p>
      </div>
    </div>
  </section>
</template>

<script>
  import IncorporationBox from './IncorporationBox'
  import { PACKAGES } from '@/utils/constants'

  export default {
    name: 'Incorporations',
    components: { IncorporationBox },
    data () {
      return {
        packages: PACKAGES
      }
    },
    methods: {
      displayOrderInfo () {
        this.$buefy.dialog.alert({
          title: this.$t('incorporation.options.orderNoPrice'),
          message: this.$t('incorporation.options.orderInfo'),
          confirmText: 'OK',
          canCancel: ['escape', 'outside']
        })
      }
    }
  }
</script>
