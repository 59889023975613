<template>
  <router-link
    v-on:click.native="selectProduct"
    :to="{ name: 'incorporation-form' }"
    role="button"
    class="box incorporation-box"
    :class="`incorporation-box--${region}`"
  >
    <img
      class="incorporation-box__background"
      :src="getBackgroundImage()"
    >
    <div class="incorporation-box__content">
      <div>
        <p class="incorporation-box__title">{{ title }}</p>
        <p class="incorporation-box__subtitle">{{ subtitle }}</p>
      </div>
      <p class="incorporation-box__price">{{ $i18n.locale === 'en' ? '$' : '' }}{{ price }}{{ $i18n.locale === 'fr' ? '$' : '' }}</p>
    </div>
    <p class="incorporation-box__start">{{ $t('incorporation.choose') }}
      <b-icon
        pack="fas"
        size="is-small"
        icon="arrow-right"
      ></b-icon>
    </p>
  </router-link>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'IncorporationBox',
  props: {
    product: String,
    region: String,
    title: String,
    subtitle: String,
    price: Number
  },
  methods: {
    ...mapActions('form', [
      'startForm'
    ]),
    getBackgroundImage () {
      if (this.region === 'quebec') {
        return '/fleurdelys-white.svg';
      } else if (this.region === 'canada') {
        return '/mapleleaf-white.svg';
      }
    },
    selectProduct () {
      this.startForm(this.product);
    }
  }
};
</script>
